import React from 'react';
//import { useIsAuthenticated } from "@azure/msal-react";

function FooterSection() {
    //const isAuthenticated = useIsAuthenticated();

    return (
        <footer className="footer">
            <p><a href="https://nyx.baby">Privacy Policy & Terms of Use</a></p>
            <p>&copy; Copyright 2024-2025, <a href="https://nyx.baby" alt="NYX NoCode website" title={"NYX NoCode"}>NYX NoCode</a>, All Rights Reserved</p>
        </footer>
    );
}

export default FooterSection;
