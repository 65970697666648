import React from 'react';
import { SignOutButton } from "./SignOutButton";
import { useIsAuthenticated } from "@azure/msal-react";

function HeaderSection() {
    const isAuthenticated = useIsAuthenticated();

    return (
        <header className="App-header">
            {isAuthenticated && <SignOutButton />}
        </header>
    );
}

export default HeaderSection;
